import { isObject } from '../../../client/src/lib/getVariableType'
import deepCompare  from '../../../client/src/lib/compareObjects'

export const areSame = (a, b) => {
  let same = true
  if (isObject(a) && isObject(b)) {
    same = deepCompare(a, b)
  } else {
    same = a === b
  }
  return same
}
