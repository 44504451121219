export class NABServerError extends Error{
  constructor(name, payload = {}) {
    super('')
    Object.keys(payload)
      .filter(key => key !== 'err')
      .forEach(key => {this[key] = payload[key]})
    this.message = this.message || ''
    this.name = name || ''
    if (payload.err) {
      this.message += ' ' + (payload.err?.message)
      this.name = `${ name }__${ payload.err?.name.replace(/[.-]/g, '__') }`
      this.stack = payload.stack || payload.err?.stack
    }
    // console.log('trace', console.trace('<<<< ' + this.name))

    this.stack = this.stack || console.trace('<<< ' + this.name)
  }
}
