export const iterateArray = ({
                        array,
                        onItem,
                        onDone,
                      }) => {
  for (let i = 0; i < array.length; i++) {
    const item = array[i]
    if (onItem) onItem(item, array, i)
  }
  if (onDone) onDone(array)
}

export const flattenArray = array => iterateArray({
  array,
  onItem: (item, array) => {
    if (Array.isArray(item)) iterateArray({
      array: item,
      onItem: item => array.push(item)
    })
  }
})

export const filterArray = (array, filter) => iterateArray({
  array,
  onItem: (item, array, i) => !filter(item, array, i) && array.splice(i, 1)
})

export const createArrayWithDefaultValue = (defaultValue = 'defaultValue', length = 1) =>
  Array.apply(null, Array(length).map(() => defaultValue))

// todo: relocate into filters?
export const isFirstOfItsValueInArray = (arrayItem, index, self) => self.indexOf(arrayItem) === index
