export default {
  resetPassphrase: {
    tokenName: 'resetPassphraseToken',
    clientUrlPart: 'resetPassphrase',
    serverUrlPart: 'resetPassphrase',
    tokenMaxAge: { minutes: 15 },
  },
  abandonEmail: {
    tokenName: 'abandonEmailToken',
    clientUrlPart: 'confirmChangingEmail',
    serverUrlPart: 'abandonEmail',
    tokenMaxAge: { hours: 1 },
  },
  validateChangedEmail: {
    tokenName: 'validateChangedEmailToken',
    clientUrlPart: 'validateChangedEmail',
    serverUrlPart: 'validateChangedEmail',
    tokenMaxAge: { hours: 1 },
  },
  validateEmail: {
    tokenName: 'validateEmailToken',
    clientUrlPart: 'validateEmail',
    serverUrlPart: 'validateEmail',
  }
}
