// export function capitalize(str) {
//   return `${ str[0].toUpperCase() }${ str.substring(1) }`
// }
//
// export function decapitalize(Str = '') {
//   return Str[0].toLowerCase() + Str.substring(1)
// }

export const capitalize = string => {
  let capitalized = ''
  string = string ?? ''
  string = string.toString()
  if (string) {
    return `${ string.charAt(0).toUpperCase() }${ string.substring(1) }`
  }
  return capitalized
}
export const decapitalize = (string = '') => {
  let decapitalized = ''
  string = string ?? ''
  string = string.toString()
  if (string) {
    return string.charAt(0).toLowerCase() + string.substring(1)
  }
  return decapitalized
}
export const withoutPrefix = (string = '', prefix = '') => {
  if (string.startsWith(prefix)) string = string.substr(prefix.length)
  return string
}
export const withPrefix = (string = '', prefix = '') => {
  if (!string.startsWith(prefix)) string = prefix + string
  return string
}
